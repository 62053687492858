import React, { useState } from 'react';
import PropTypes from "prop-types"

const Vitrine = ({ nom, description, hauteur, largeur, profondeur, lumiere, consommation, photos, prix, slug, mw }) => {
  const [ledActive, setLedActive] = useState(0);
  const classActive = ["coul_eclairage_j", "coul_eclairage_b", "coul_eclairage_ub"];
  

  return (
  <div className="lg:flex pb-32" id={slug}>
    <div className="lg:w-5/12 lg:flex justify-end">
      {photos[1] && (
        <div className='hidden lg:block mt-20'>
          <img src={photos[1]} alt={nom + " - Location de vitrines en France, en Belgique et au Luxembourg"} className={"w-28"} />
          <img src={photos[2]} alt={nom + " - Location de vitrines en France, en Belgique et au Luxembourg"} className={"w-28"} />
          <img src={photos[3]} alt={nom + " - Location de vitrines en France, en Belgique et au Luxembourg"} className={"w-28"} />
        </div>
      )}
      <img src={photos[0]} alt={nom + " - Location de vitrines en France, en Belgique et au Luxembourg"} className={"mx-auto  w-full lg:w-auto lg:mx-0 "+ classActive[ledActive]} style={{maxWidth:mw}} />
      
      {photos[1] && (
        <div className='lg:hidden flex justify-center mt-20'>
          <img src={photos[1]} alt={nom + " - Location de vitrines en France, en Belgique et au Luxembourg"} className={"w-28 mx-2"} />
          <img src={photos[2]} alt={nom + " - Location de vitrines en France, en Belgique et au Luxembourg"} className={"w-28 mx-2"} />
          <img src={photos[3]} alt={nom + " - Location de vitrines en France, en Belgique et au Luxembourg"} className={"w-28 mx-2"} />
        </div>
      )}
      
    </div>
    <div className="lg:w-7/12">
      <div className="mx-10 lg:max-w-2xl lg:mr-10 lg:ml-28">
        <div className='lg:hidden flex items-center justify-center align-items-center mb-5'>
          <div className={'ease-in-out duration-300 relative cursor-pointer rounded-full w-8 h-8 bg-led1 hover:opacity-80 ease-in-out duration-300 ' + (ledActive === 0 && " after:content-[' '] after:absolute after:w-7 after:h-7 after:left-sel-coul after:top-sel-coul after:rounded-full after:bg-led1 after:border-4 after:border-produits")} onClick={() => {setLedActive(0)}}></div>
          
          
          
          <div className={'ease-in-out duration-300 relative cursor-pointer rounded-full w-8 h-8 bg-led2 mx-5 hover:opacity-80 ease-in-out duration-300' + (ledActive === 1 && " after:content-[' '] after:absolute after:w-7 after:h-7 after:left-sel-coul after:top-sel-coul after:rounded-full after:bg-led2 after:border-4 after:border-produits")} onClick={() => {setLedActive(1)}}></div>
          <div className={'ease-in-out duration-300 relative cursor-pointer rounded-full w-8 h-8 bg-led3 hover:opacity-80 ease-in-out duration-300' + (ledActive === 2 && " after:content-[' '] after:absolute after:w-7 after:h-7 after:left-sel-coul after:top-sel-coul after:rounded-full after:bg-led3 after:border-4 after:border-produits")} onClick={() => {setLedActive(2)}}></div>
        </div>
        
        <h3 className="text-white text-4xl uppercase">{nom}</h3>
        <div className="text-white border-l-8 border-txt-produit my-12 pl-8" dangerouslySetInnerHTML={{__html: description}}>
        </div>
        <div className="sssm:flex items-start justify-center align-items-top">
          <div>
            <span className="text-sous-titre-produit mb-2 block">Dimensions </span>
            <div className="flex items-center justify-center align-items-center">
              <p className="text-sm text-white/50 m-0">H</p>
              <div className="h-px grow bg-white/25 mx-2"></div>
              <p className="text-sm text-white m-0">{hauteur}</p>
            </div>
            <div className="flex items-center justify-center align-items-center">
              <p className="text-sm text-white/50 m-0">L</p>
              <div className="h-px grow bg-white/25 mx-2"></div>
              <p className="text-sm text-white m-0">{largeur}</p>
            </div>
            <div className="flex items-center justify-center align-items-center">
              <p className="text-sm text-white/50 m-0">P</p>
              <div className="h-px grow bg-white/25 mx-2"></div>
              <p className="text-sm text-white m-0">{profondeur}</p>
            </div>
          </div>
          <div className="sssm:mx-10 sssm:my-0 my-10 grow">
            <span className="text-sous-titre-produit mb-2 block">Lumières </span>
            <div className="text-sm text-white" dangerouslySetInnerHTML={{__html: lumiere}}>
            </div>
            <div className='hidden lg:flex items-center justify-start align-items-center'>
              <div className={'ease-in-out duration-300 relative cursor-pointer rounded-full w-8 h-8 bg-led1 hover:opacity-80 ease-in-out duration-300 ' + (ledActive === 0 && " after:content-[' '] after:absolute after:w-7 after:h-7 after:left-sel-coul after:top-sel-coul after:rounded-full after:bg-led1 after:border-4 after:border-produits")} onClick={() => {setLedActive(0)}}></div>
              
              
              
              <div className={'ease-in-out duration-300 relative cursor-pointer rounded-full w-8 h-8 bg-led2 mx-5 hover:opacity-80 ease-in-out duration-300' + (ledActive === 1 && " after:content-[' '] after:absolute after:w-7 after:h-7 after:left-sel-coul after:top-sel-coul after:rounded-full after:bg-led2 after:border-4 after:border-produits")} onClick={() => {setLedActive(1)}}></div>
              <div className={'ease-in-out duration-300 relative cursor-pointer rounded-full w-8 h-8 bg-led3 hover:opacity-80 ease-in-out duration-300' + (ledActive === 2 && " after:content-[' '] after:absolute after:w-7 after:h-7 after:left-sel-coul after:top-sel-coul after:rounded-full after:bg-led3 after:border-4 after:border-produits")} onClick={() => {setLedActive(2)}}></div>
            </div>
          </div>
          <div>
            <span className="text-sous-titre-produit mb-2 block">Consommation  </span>
            <div className="flex items-center justify-center align-items-center">
              <p className="text-sm text-white/50 m-0">Watts</p>
              <div className="h-px grow bg-white/25 mx-2"></div>
              <p className="text-sm text-white m-0">{consommation}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

}

Vitrine.propTypes = {
  nom:PropTypes.string,
  description:PropTypes.string,
  hauteur:PropTypes.string,
  largeur:PropTypes.string,
  profondeur:PropTypes.string,
  lumiere:PropTypes.string,
  consommation:PropTypes.string,
  photos:PropTypes.array,
  prix:PropTypes.string,
  slug:PropTypes.string,
  mx:PropTypes.string,
}

Vitrine.defaultProps = {
  siteTitle: ``,
  nom:"",
  description:"",
  hauteur:"",
  largeur:"",
  profondeur:"",
  lumiere:"",
  consommation:"",
  photos:[],
  prix:"",
  slug:"",
  mw:"",
}

export default Vitrine
